<template>
  <div
    class="avue-data-progress"
    :class="b()"
    :style="styleSizeName"
    ref="main"
    @click="handleClick">
    <!-- 多个 -->
    <div v-if="option.multiple">
      <div class="multiple_wrapper" :style="multipleListStyle">
        <div
          v-for="(item,index) in dataChart"
          :key="index"
          @click="itemClick(item)"
          class="multiple_wrapper__item"
          style="margin-bottom: 10px;">
          <div :class="b('text', { line: type === 'line', circle: type === 'circle' })">
            <span :style="styleIndexSuffixName">{{ index + 1 }}</span>
            <span :style="styleSuffixName" class="multiple_wrapper__item__suffix">{{ item.label }}</span>
          </div>
          <el-row type="flex">
            <el-col :span="21">
              <avue-progress
                :color="color"
                :width="width"
                :showText="false"
                :strokeWidth="strokeWidth"
                :percentage="item.data"
                :type="type">
              </avue-progress>
            </el-col>
            <el-col :span="3" style="text-align:center; color: #BECEFF; margin-top: -2px">
              {{ item.data }}%
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 单个 -->
    <div v-else>
      <div :class="b('text', { line: type === 'line', circle: type === 'circle' })">
        <p :style="styleSuffixName">{{ dataChart.label }}</p>
      </div>
      <avue-progress
        :color="color"
        :width="width"
        :showText="false"
        :strokeWidth="strokeWidth"
        :percentage="dataChart.data"
        :type="type">
      </avue-progress>
    </div>
  </div>
</template>

<script>
import create from '../../create'
export default create({
  name: 'progress',
  computed: {
    styleSuffixName() {
      return {
        fontWeight: this.option.suffixFontWeight || 'normal',
        fontSize: (this.option.suffixFontSize || 40) + 'px',
        color: this.option.suffixColor || '#333',
        margin: this.option.suffixFontMargin || '0'
      }
    },
    styleIndexSuffixName() {
      return {
        fontWeight: this.option.suffixFontWeight || 'normal',
        fontSize: (this.option.suffixFontSize || 40) + 'px',
        color: '#FFAD05',
        margin: this.option.suffixFontMargin || '0',
        'margin-right': '10px'
      }
    },
    styleName() {
      return {
        marginBottom: this.option.split + 'px',
        fontWeight: this.option.fontWeight || 'normal',
        fontSize: (this.option.fontSize || 40) + 'px',
        color: this.option.color || '#333'
      }
    },
    multipleListStyle() {
      return {
        height: this.option.multipleListHeight
      }
    },
    type() {
      return this.option.type || 'line'
    },
    color() {
      return this.option.borderColor || '#333'
    },
    strokeWidth() {
      return this.option.strokeWidth || 14
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    handleClick() {
      this.clickFormatter &&
        this.clickFormatter({
            data: this.dataChart
          },
          this.getItemRefs()
        )
    },
    // 数据点击事件
    itemClick(item) {
      new Function('item', this.option.itemClikFuncBody)(item)
    }
  }
})
</script>

<style lang="scss">
.avue-data-progress {
  overflow-y: scroll;
}

.avue-data-progress .el-progress-bar__outer {
  background-color: #ebeef52e;
  border: 1px solid #ebeef52e;
}

.avue-data-progress .multiple_wrapper__item:hover .multiple_wrapper__item__suffix {
  transition: color .25s ease-in-out;
  color: #FFAD05 !important;
}
</style>
